import React, { useEffect, useState } from 'react'
import * as MUI from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MdClose, MdSearch } from 'react-icons/md'
import styled from 'styled-components'
import { Autocomplete } from '@material-ui/lab'
import { API } from 'api'
import emptyImg from '../../assets/img/empty-img.png'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
	'@global': {
		'.MuiAutocomplete-option[aria-selected="true"]': {
			background: 'white',
		},
		'.MuiAutocomplete-option[data-focus="true"]': {
			background: 'rgba(0, 0, 0, 0.08)',
		},
	},
})

const SearchBar = ({
	value = '',
	onChange,
	onReset,
	onSubmit,
	full,
	disabled,
	autoCompleteUrl,
	type,
}) => {
	const { t } = useTranslation()
	const [isFocus, setIsFocus] = React.useState(false)
	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [searching, setSearching] = useState(false)
	const [pendingSearch, setPendingSearch] = useState(false)
	const classes = useStyles()
	const history = useHistory()

	const handleAutomcompleteClick = value => {
		switch (type) {
			case 'channels':
				history.push({
					pathname: `/analytics/search/${value.id}`,
					state: {
						channel: {
							id: value.id,
							display_name: value.value,
							logo: value.logo,
							banner: value.banner,
							handle: value.handle,
						},
					},
				})
				break
			case 'brands':
				history.push({
					pathname: `/brands/search/${value.id}`,
					state: {
						brand: {
							id: value.id,
							name: value.value,
							logo: value.logo,
							wizdeo_sponsored_videos_2y:
								value.wizdeo_sponsored_videos_2y,
						},
					},
				})
				break
			default:
				onSubmit()
		}
	}

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			onSubmit(e)
		}
	}

	const doAutocomplete = () => {
		return setTimeout(() => {
			setSearching(true)
			setOptions([])
			setOpen(true)
			API.get(autoCompleteUrl, {
				params: { query: value },
			}).then(res => {
				if (res.data.suggestions) setOptions(res.data.suggestions)
				setSearching(false)
			})
		}, 1000)
	}

	useEffect(() => {
		if (autoCompleteUrl) {
			if (pendingSearch) {
				clearTimeout(pendingSearch)
				setPendingSearch(false)
			}

			if (value && value.length >= 3) {
				setPendingSearch(doAutocomplete())
			} else {
				setSearching(false)
			}
		}
	}, [value])

	return autoCompleteUrl ? (
		<CustomAutocomplete
			id='asynchronous-demo'
			sx={{ width: 300 }}
			open={open}
			onOpen={() => {
				if (searching || options.length > 0) setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			onChange={(event, value, reason, details) => {
				if (reason === 'clear') {
					setOptions([])
					onReset()
					setOpen(false)
				} else if (reason === 'select-option') {
					setSearching(false)
					onChange({ target: { value: value.value } })
					handleAutomcompleteClick(value)
				}
			}}
			getOptionLabel={option =>
				typeof option === 'string' ? option : option.value
			}
			getOptionSelected={(option, value) =>
				typeof value === 'string' ? true : option.value === value.value
			}
			options={options}
			loading={searching}
			popupIcon={<MdSearch size={20} color='grey' />}
			value={value !== '' ? value : null}
			noOptionsText={t('search.autocomplete_no_options')}
			loadingText={t('search.autocomplete_loading')}
			PaperComponent={({ children }) => (
				<MUI.Paper className={classes['@global']}>{children}</MUI.Paper>
			)}
			renderInput={params => (
				<MUI.TextField
					{...params}
					margin='dense'
					onKeyPress={handleKeyPress}
					onChange={onChange}
					variant='outlined'
					placeholder={t('search.placeholder')}
					value={value}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{searching ? (
									<MUI.CircularProgress
										color='inherit'
										size={20}
									/>
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
			renderOption={(props, option) => {
				return (
					<AutocompleteItemWrapper>
						<AutocompleteItemLogo
							src={props.logo}
							alt={props.value}
							height='30'
							width='30'
							onError={e => {
								e.target.onerror = null
								e.target.src = emptyImg
							}}
						/>
						<AutocompleteItemValue>
							{props.value}
						</AutocompleteItemValue>
					</AutocompleteItemWrapper>
				)
			}}
		/>
	) : (
		<Wrapper full={full} $focus={isFocus} elevation={0} variant='outlined'>
			<Input
				disabled={disabled}
				onKeyPress={handleKeyPress}
				onFocus={() => setIsFocus(true)}
				onBlur={() => setIsFocus(false)}
				placeholder={t('search.placeholder')}
				value={value}
				onChange={onChange}
			/>
			<Divider orientation='vertical' />
			{value ? (
				<MdClose size={20} color='grey' onClick={onReset} />
			) : (
				<MdSearch size={20} color='grey' />
			)}
		</Wrapper>
	)
}

export default SearchBar

const CustomAutocomplete = styled(Autocomplete)`
	& .MuiInputBase-input {
		font-size: 0.8em !important;
	}

	& .MuiInputBase-root {
		background-color: white;
	}

	& .MuiAutocomplete-popupIndicatorOpen {
		transform: none !important;
	}
`

const AutocompleteItemWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	min-width: 0;
`

const AutocompleteItemLogo = styled.img`
	border-radius: 50%;
`
const AutocompleteItemValue = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const Wrapper = styled(MUI.Paper)`
	${props => (props.full ? 'width: calc(100% - 16px);' : null)}
	margin-bottom: ${props => (props.full ? 16 : 0)}px;
	display: flex;
	align-items: center;
	padding: 4px 10px;
	${props => (props.$focus ? 'border-color: #1976D2;' : null)}
`

const Divider = styled(MUI.Divider)`
	margin: 10px !important;
`

const Input = styled(MUI.InputBase)`
	font-size: 14px !important;
	flex: 1;
`
