import React from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal'
import { useDispatch } from 'react-redux'
import { manageDeal } from '../../../store/reducers/dealsReducer'

const AcceptModal = ({ deal, isOpen, onRequestClose }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const accept = () => {
        if (window.confirm(t('deals.modal.accept.prompt'))) {
            const payload = {
                dealAccept: true,
                dealId: deal.id,
                previousStatus: deal.status,
                newStatus: 'approved',
            }

            dispatch(manageDeal(payload))
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            title={t('deals.modal.accept.title', { campaign: deal?.campaign?.title })}
            description={t('deals.modal.accept.description')}
            actions={
                <Button color='primary' variant='contained' onClick={accept}>
                    {t('deals.accept')}
                </Button>
            }
        />
    )
}

export default AcceptModal
