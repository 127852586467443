import React from 'react'
import { Card, CardContent, Tooltip, Typography } from '@material-ui/core'
import Carousel, {
	arrowsPlugin,
	slidesToShowPlugin,
	slidesToScrollPlugin,
} from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaQuestionCircle, FaUsers } from 'react-icons/fa'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import { formatCurrencies, shorten } from '../helpers/numbersHelper'
import { useTranslation } from 'react-i18next'
import NoData from './UI/NoData'
import { useHistory } from 'react-router-dom'
import ChannelCard from './ChannelCard'

const CrosschecksCarousel = ({ type, closeChannels }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const handleChannelClick = channel => {
		history.push({
			pathname: `/analytics/search/${channel.id}`,
			state: { channel },
		})
	}

	const getKpisContent = channel => {
		return type === 'twitch' ? (
			<Typography component='div'>
				<KpisList>
					<div style={{ width: '50%' }}>
						<dl>
							<dt>{t('twitch.kpis.followers')}</dt>
							<dd>{shorten(channel.followers, 0)}</dd>
						</dl>
					</div>

					<div style={{ width: '50%' }}>
						<dl>
							<dt>
								{t('twitch.kpis.sponsored_sessions_percentage')}{' '}
								<Tooltip
									title={t(
										'twitch.kpis.sponsored_sessions_percentage_tooltip'
									)}
									placement='left'
									arrow
								>
									<sup>
										<FaQuestionCircle size='0.8em' />
									</sup>
								</Tooltip>
							</dt>
							<dd>
								{channel.sponsored_sessions_6m_percentage
									? shorten(
											channel.sponsored_sessions_6m_percentage,
											0
									  ) + '%'
									: '-'}
							</dd>
						</dl>
					</div>
				</KpisList>
			</Typography>
		) : (
			<Typography component='div'>
				<KpisList>
					<div style={{ width: '50%' }}>
						<dl>
							<dt>{t('channel.kpis.subscribers')}</dt>
							<dd>{shorten(channel.subscribers, 0)}</dd>
						</dl>

						<dl>
							<dt>
								{t(
									'channel.kpis.sponsored_videos_6m_percentage'
								)}{' '}
								<Tooltip
									title={t(
										'channel.kpis.sponsored_videos_6m_percentage_tooltip'
									)}
									placement='left'
									arrow
								>
									<sup>
										<FaQuestionCircle size='0.8em' />
									</sup>
								</Tooltip>
							</dt>
							<dd>
								{channel.sponsored_videos_6m_percentage
									? shorten(
											channel.sponsored_videos_6m_percentage,
											0
									  ) + '%'
									: '-'}
							</dd>
						</dl>
					</div>

					<div style={{ width: '50%' }}>
						<dl>
							<dt>{t('channel.kpis.cpm_min')}</dt>
							<dd>{formatCurrencies(channel.cpm_min)}</dd>
						</dl>
						<dl>
							<dt>{t('channel.kpis.cpm_max')}</dt>
							<dd>{formatCurrencies(channel.cpm_max)}</dd>
						</dl>
					</div>
				</KpisList>
			</Typography>
		)
	}

	return (
		<>
			{closeChannels?.length ? (
				<CarouselWrapper>
					<Carousel
						plugins={[
							{
								resolve: arrowsPlugin,
								options: {
									arrowLeft: (
										<CarouselButtonWrapper
											$left
											className='carousel-button-wrapper'
										>
											<CarouselButton>
												<CarouselButtonBg $left />
												<CarouselButtonIcon>
													<FontAwesomeIcon
														icon={faChevronLeft}
													/>
												</CarouselButtonIcon>
											</CarouselButton>
										</CarouselButtonWrapper>
									),
									arrowLeftDisabled: <></>,
									arrowRight: (
										<CarouselButtonWrapper
											$right
											className='carousel-button-wrapper'
										>
											<CarouselButton>
												<CarouselButtonBg $right />
												<CarouselButtonIcon>
													<FontAwesomeIcon
														icon={faChevronRight}
													/>
												</CarouselButtonIcon>
											</CarouselButton>
										</CarouselButtonWrapper>
									),
									arrowRightDisabled: <></>,
									addArrowClickHandler: true,
								},
							},
							{
								resolve: slidesToShowPlugin,
								options: { numberOfSlides: 3 },
							},
							{
								resolve: slidesToScrollPlugin,
								options: {
									numberOfSlides: 3,
								},
							},
						]}
						breakpoints={{
							1100: {
								plugins: [
									{
										resolve: slidesToShowPlugin,
										options: {
											numberOfSlides: 2,
										},
									},
									{
										resolve: slidesToScrollPlugin,
										options: {
											numberOfSlides: 2,
										},
									},
								],
							},
							531: {
								plugins: [
									{
										resolve: slidesToShowPlugin,
										options: {
											numberOfSlides: 1,
										},
									},
									{
										resolve: slidesToScrollPlugin,
										options: {
											numberOfSlides: 1,
										},
									},
								],
							},
						}}
					>
						{closeChannels.map(closeChannel => (
							<CarouselItemWrapper
								key={
									type === 'twitch'
										? closeChannel.twitch_channel.id
										: closeChannel.id
								}
							>
								{console.log(closeChannel.handle)}
								<ChannelCard
									hideBanner={type === 'twitch'}
									channel={
										type === 'twitch'
											? closeChannel.twitch_channel
											: closeChannel
									}
									subheader={type === 'twitch' ? null : '@' + closeChannel.handle}
									handleClick={() => {
										if (type !== 'twitch')
											handleChannelClick(closeChannel)
									}}
									cardcontent={getKpisContent(
										type === 'twitch'
											? closeChannel.twitch_channel
											: closeChannel
									)}
								/>
							</CarouselItemWrapper>
						))}
					</Carousel>
				</CarouselWrapper>
			) : (
				<Card>
					<CardContent>
						<NoData icon={<FaUsers />} />
					</CardContent>
				</Card>
			)}
		</>
	)
}

export default CrosschecksCarousel

const CarouselWrapper = styled.div`
	position: relative;
	&:hover .carousel-button-wrapper {
		opacity: 1;
	}
`

const CarouselButtonWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	${props => props.$left && 'left: 0;'}
	${props => props.$right && 'right: 0;'}
	opacity: 0;
	transition: 0.5s;
	z-index: 1;
`

const CarouselButton = styled.div`
	position: relative;
	font-size: 1.8em;
	color: white;
	font-weight: bold;
	height: 100%;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1;
`

const CarouselButtonBg = styled.div`
	background-color: black;
	opacity: 0.5;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 0;
	${props =>
		props.$left &&
		'border-top-left-radius: 4px; border-bottom-left-radius: 4px;'}
	${props =>
		props.$right &&
		'border-top-right-radius: 4px; border-bottom-right-radius: 4px;'}
`

const CarouselButtonIcon = styled.div`
	z-index: 1;
`

const CarouselItemWrapper = styled.div`
	width: 100%;
	padding: 5px;
`

const KpisList = styled.div`
	display: flex;
	text-align: right;
	justify-content: space-between;
`
