import moment from 'moment'
import { FaCheck, FaRegClock } from 'react-icons/fa'
import { MdOutlineMarkEmailRead } from 'react-icons/md'
import scamLogo from '../assets/img/logo_scam.png'
import sacdLogo from '../assets/img/sacd.png'
import { translate } from '../i18n/i18n'
import { formatCurrencies } from './numbersHelper'

const getFormattedStatus = status => {
    const labels = {
        paid: translate('royalties.status.short.paid'),
        in_progress: translate('royalties.status.short.in_progress'),
        received: translate('royalties.status.short.received'),
    }

    const color = status === 'paid' ? 'green' : (status === 'received' ? 'red' : 'orange')
    const label = status === 'paid' ? labels.paid : (status === 'in_progress' ? labels.in_progress : labels.received)
    const Icon = status === 'paid' ? FaCheck : (status === 'in_progress' ? FaRegClock : MdOutlineMarkEmailRead)

    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Icon size={14} color={color} style={{ marginRight: 10 }} />
            <span style={{ fontSize: 14, color, fontWeight: 500 }}>
                {label}
            </span>
        </div>
    )
}

export const getRoyaltiesTabLabels = () => ({
    sacd: <img src={sacdLogo} height='24px' alt='SACD Logo'/>,
    scam: <img src={scamLogo} height='24px' alt='SCAM Logo'/>,
})
export const getRoyaltiesStatusOptions = () => [
    { label: translate('royalties.status.all'), value: 'all' },
    { label: translate('royalties.status.paid'), value: 'paid' },
    { label: translate('royalties.status.in_progress'), value: 'in_progress' },
    { label: translate('royalties.status.received'), value: 'received' },
]

export const getFormattedRoyalties = (royalties, page, type) => {
	console.log(royalties);

    let columns = [
        translate('royalties.table.date'),
        <div>
            <span>{translate('royalties.table.gross_amount')}</span>
            <br />
            {type === 'sacd' && (
                <span class='text-xsmall text-italic'>
                    {translate('royalties.corrections')}
                </span>
            )}
        </div>,
        translate('royalties.partner'),
        translate('deals.status_title'),
    ]

    let tableData = royalties[page].map(sheet => {
        let row = {
            cells: [
                moment(sheet.file_date).format('LL'),
                <div>
                    <span>{formatCurrencies(sheet.gross_amount)}</span>
                    <br />
                    {Boolean(type === 'sacd' && sheet.amount_corrections !== null) && (
                        <span class='text-xsmall text-italic'>
                            {formatCurrencies(sheet.amount_corrections)}
                        </span>
                    )}
                </div>,
                formatCurrencies(
                    type === 'sacd'
                        ? sheet.amount_partner
                        : sheet.partner_amount
                ),
                getFormattedStatus(sheet.status),
            ],
        }

        return row
    })

    return { columns, tableData }
}
