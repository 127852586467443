import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import GmDeals from '../components/gm/GmDeals'
import GmRecap from '../components/gm/GmRecap'
import { Container } from '../components/Layout'
import Header from '../components/layout/Header'
import Note from '../components/UI/Note'
import { formatCurrencies } from '../helpers/numbersHelper'
import { getTalentActivity } from '../helpers/talentHelper'
import { translate } from '../i18n/i18n'
import styled from 'styled-components'
import Avatar from '../components/UI/Avatar'
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import {sendInterested} from "../store/reducers/gmReducer";
import Loader from "../components/UI/Loader";

const TABS = ['summary', 'deals']

const getIndex = tab => TABS.findIndex(item => item === tab)

const GmPage = () => {
	// HOOKS
	const { tab = TABS[0] } = useParams()
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const { i18n } = useTranslation()

	// GLOBAL STATE
	const { gm } = useSelector(state => state.badges)
	const { interested, loading } = useSelector( state => state.gm )

	// LOCAL STATE
	const [tabIndex, setTabIndex] = useState(getIndex(tab))

	// EFFECT HOOKS
	useEffect(() => {
		history.push({
			pathname: `/gm/${TABS[tabIndex]}`,
			search: location.search,
		})
	}, [tabIndex])

	useEffect(() => {
		const newIndex = getIndex(tab)
		setTabIndex(newIndex)
	}, [tab])

	// HANDLERS
	const handleSelect = index => setTabIndex(index)
	const handleInterest = async () => {
		const payload = {amount: amount}
		dispatch( sendInterested(payload) )
	}

	// RENDER
	const { talentHasGm, talentHasGmPotential } = getTalentActivity()

	const amount = talentHasGmPotential ? formatCurrencies(gm?.gmPotential * 12) : null;

	let content

	if (talentHasGm) {
		content = (
			<Tabs selectedIndex={tabIndex} onSelect={handleSelect}>
				<TabList>
					<Tab>{translate('gm.recap')}</Tab>
					<Tab>{translate('gm.deals')}</Tab>
				</TabList>
				<Container>
					<TabPanel>
						<GmRecap />
					</TabPanel>
					<TabPanel>
						<GmDeals />
					</TabPanel>
				</Container>
			</Tabs>
		)
	} else if (talentHasGmPotential) {

		const cardContent = (
			<CardContent>
				<h4 className='text-center'>
					{translate('gm.has_potential.title', {
						amount: formatCurrencies(gm?.gmPotential * 12),
					})}
				</h4>
				<p>
					{translate('gm.has_potential.message', {
						amount: formatCurrencies(gm?.gmPotential * 12),
					})}
				</p>
				<p>
					<small>{translate('gm.related_channels')}</small>
					<RelatedChannels>
						{gm.channels.map(channel => (
							<Avatar
								key={channel.id}
								src={channel.logo}
								label={channel.display_name}
							/>
						))}
					</RelatedChannels>
				</p>
				<span className='text-italic text-small'>
							{translate('gm.has_potential.asterisk')}
						</span>
			</CardContent>
		)

		const cardAction = (
			( !loading && interested) ?
				( <CardContent>
					<div style={{
							textAlign: 'center',
							fontStyle: 'italic',
							width: '100%',
						}}
					>
						{ i18n.t('You have indicated that you are interested in our offer.') }
						{ i18n.t('Your request has been forwarded to our agents who will contact you as soon as possible.') }
					</div>
				</CardContent> ) :
				( !loading ? ( <RightCardActions disableSpacing>
										<Button size="small" color="primary" variant="contained" onClick={handleInterest}>
											{translate('gm.interested')}
										</Button>
									</RightCardActions> ) :
								( <CardContent><Loader /></CardContent> ) ) )

		content = (
			<Container>
				<Card>
					{cardContent}
					{cardAction}
				</Card>
			</Container>
		)
	} else {
		content = (
			<Container>
				<Note>
					<span>{translate('gm.empty')}</span>
					<br></br>
					<a
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'underline' }}
						href={`https://blog.wizdeo.com/${i18n.language}/59-monetization/1224-youtube-influencers-get-guaranteed-annual-revenue-for-your-sponsorship-deals`}
					>
						{translate('common.more_information')}
					</a>
				</Note>
			</Container>
		)
	}

	return (
		<>
			<Header title={translate('pages.gm')} />
			{content}
		</>
	)
}

const RelatedChannels = styled.div`
	display: flex;
	gap: 24px;
`

const RightCardActions = styled(CardActions)`
	justify-content: center;
`

const ButtonRight = styled(Button)`
	marginLeft: "auto"
`
export default GmPage
