import React, { useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'
import { AUTH } from '../../store/reducers/talentReducer'

import PrivateRoute from './PrivateRoute'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { CustomToaster } from '../UI/notifications'

import AgendaPage from '../../pages/AgendaPage'
import DealPage from '../../pages/deals/DealPage'
import DealsPage from '../../pages/deals/DealsPage'
import GmPage from '../../pages/GmPage'
import HomePage from '../../pages/HomePage'
import LoginPage from '../../pages/auth/LoginPage'
import LogoutPage from '../../pages/auth/LogoutPage'
import RevenuesPage from '../../pages/RevenuesPage'
import RoyaltiesPage from '../../pages/RoyaltiesPage'
import SettingsPage from '../../pages/SettingsPage'
import TasksPage from '../../pages/tasks/TasksPage'
import ClaimsPage from '../../pages/mcn/ClaimsPage'
import SearchPage from '../../pages/analytics/SearchPage'
import BrandsSearchPage from '../../pages/brands/BrandsSearchPage'
import ChannelPage from '../../pages/analytics/ChannelPage'
import BrandPage from '../../pages/brands/BrandPage'
import UnsubscribePage from '../../pages/UnsubscribePage'
import PublicRoute from './PublicRoute'
import ChatPage from '../../pages/ChatPage'
import StrikesPage from '../../pages/mcn/StrikesPage'
import CopyrightsPage from '../../pages/mcn/CopyrightsPage'
import { getTalentActivity } from '../../helpers/talentHelper'
import ServicePage from 'pages/ServicePage'
import BrandSuggested from 'pages/brands/BrandSuggested'

const AppNavigator = () => {
	const { t } = useTranslation()

	const { status } = useSelector(state => state.talent)

	const [collapsed, setCollapsed] = useState(false)
	const [toggled, setToggled] = useState(false)

	const {
		talentHasStrikes,
		talentHasClaims,
		talentHasRoyalties,
		talentHasChannels,
		numberOfChannels,
		talentHasCopyrights,
	} = getTalentActivity()

	const handleCollapse = () => setCollapsed(!collapsed)
	const handleToggle = () => setToggled(!toggled)

	const authorized = status === AUTH.AUTHORIZED

	return (
		<BrowserRouter>
			<CustomToaster />
			{authorized && <Navbar handleToggle={handleToggle} />}
			<div className='app'>
				{authorized && (
					<Sidebar
						collapsed={collapsed}
						toggled={toggled}
						handleToggle={handleToggle}
						handleCollapse={handleCollapse}
					/>
				)}
				<main>
					<Switch>
						<PrivateRoute
							exact
							path='/home'
							component={HomePage}
							title={t('pages.home')}
						/>
						<PublicRoute
							exact
							path='/settings/:id'
							component={SettingsPage}
							title={t('pages.settings')}
						/>
						<PublicRoute
							exact
							path='/settings'
							component={SettingsPage}
							title={t('pages.settings')}
						/>
						<PrivateRoute
							exact
							path='/gm/:tab'
							component={GmPage}
							title={t('pages.gm')}
						/>
						<PrivateRoute
							exact
							title={t('pages.agenda')}
							path='/agenda'
							component={AgendaPage}
						/>
						<PrivateRoute
							exact
							key='sent'
							title={t('pages.deals')}
							path='/deals/sent'
							component={DealsPage}
							status='sent'
						/>
						<PrivateRoute
							exact
							key='approved'
							title={t('pages.deals')}
							path='/deals/approved'
							component={DealsPage}
							status='approved'
						/>
						<PrivateRoute
							exact
							key='in_progress'
							title={t('pages.deals')}
							path='/deals/in_progress'
							component={DealsPage}
							status='in_progress'
						/>
						<PrivateRoute
							exact
							key='done'
							title={t('pages.deals')}
							path='/deals/done'
							component={DealsPage}
							status='done'
						/>
						<PrivateRoute
							exact
							title={t('pages.deals')}
							path='/deals/view/:id'
							component={DealPage}
						/>
						<PrivateRoute
							exact
							title={t('pages.deals')}
							path='/:lang/deals/view/:id'
							component={DealPage}
						/>

						<PrivateRoute
							exact
							title={t('pages.tasks')}
							path='/tasks'
							component={TasksPage}
						/>

						<PrivateRoute
							exact
							title={t('pages.revenues')}
							path='/revenues'
							component={RevenuesPage}
						/>

						<PrivateRoute
							exact
							title={t('pages.chat')}
							path='/chat'
							component={ChatPage}
						/>

						{talentHasRoyalties && (
							<PrivateRoute
								exact
								title={t('pages.royalties')}
								path='/royalties'
								component={RoyaltiesPage}
							/>
						)}

						{talentHasClaims && (
							<PrivateRoute
								exact
								title={t('pages.claims')}
								path='/claims'
								component={ClaimsPage}
							/>
						)}

						{talentHasStrikes && (
							<PrivateRoute
								exact
								title={t('pages.strikes')}
								path='/strikes'
								component={StrikesPage}
							/>
						)}

						{talentHasCopyrights && (
							<PrivateRoute
								exact
								title={t('pages.copyrights')}
								path='/copyrights'
								component={CopyrightsPage}
							/>
						)}

						<PublicRoute
							exact
							title={t('pages.analytics.search')}
							path='/analytics/search'
							component={SearchPage}
						/>

						<PublicRoute
							exact
							title={t('pages.analytics.search')}
							path='/analytics/search/:id'
							component={ChannelPage}
						/>

						<PublicRoute
							exact
							title={t('pages.brands.search')}
							path='/brands/search'
							component={BrandsSearchPage}
						/>

						<PublicRoute
							exact
							title={t('pages.brands.search')}
							path='/brands/search/:id'
							component={BrandPage}
						/>

						<PublicRoute
							exact
							title={t('pages.brands.suggested')}
							path='/brands/suggested'
							component={BrandSuggested}
						/>

						<PublicRoute
							exact
							title={t('pages.service')}
							path='/service/:name'
							component={ServicePage}
						/>

						{talentHasChannels && (
							<PrivateRoute
								exact
								title={
									numberOfChannels > 1
										? t('pages.analytics.my_channels')
										: t('pages.analytics.my_channel')
								}
								path='/analytics/mychannel'
								component={ChannelPage}
							/>
						)}

						<PrivateRoute path='/logout' component={LogoutPage} />
						<PrivateRoute
							path='/unsubscribe'
							title={t('pages.unsubscribe')}
							component={UnsubscribePage}
						/>

						<Route path='/login' component={LoginPage} />

						<Redirect to={authorized ? '/home' : '/login'} />
					</Switch>
				</main>
			</div>
		</BrowserRouter>
	)
}

export default AppNavigator
