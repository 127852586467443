import React from 'react'
import i18n, { translate } from '../i18n/i18n'
import CustomSelect from '../components/UI/CustomSelect'
import * as Yup from 'yup'

const labels = () => ({
    private_person: translate('settings.labels.private_person', {
        locale: i18n.language,
    }),
    self_employed_under_tva: translate('settings.labels.self_employed_under_tva'),
    self_employed_beyond_tva: translate(
        'settings.labels.self_employed_beyond_tva'
    ),
    firm_with_tva: translate('settings.labels.firm_with_tva'),
    firm_with_tva_wizdeo_invoice: translate(
        'settings.labels.firm_with_tva_wizdeo_invoice'
    ),
    firm_without_tva: translate('settings.labels.firm_without_tva'),
    firm_without_tva_wizdeo_invoice: translate(
        'settings.labels.firm_without_tva_wizdeo_invoice'
    ),
    firstname: translate('settings.labels.firstname'),
    lastname: translate('settings.labels.lastname'),
    email: translate('settings.labels.email'),
    email_notifications: translate('settings.labels.email_notifications'),
    legal_structure: translate('settings.labels.legal_structure'),
    name: translate('settings.labels.name'),
    siret: translate('settings.labels.siret'),
    tva_intra: translate('settings.labels.tva_intra'),
    billing_name: translate('settings.labels.billing_name'),
    billing_email: translate('settings.labels.billing_email'),
    language: translate('settings.labels.language'),
    billing_company: translate('settings.labels.billing_company'),
    billing_street: translate('settings.labels.billing_street'),
    billing_code: translate('settings.labels.billing_code'),
    billing_city: translate('settings.labels.billing_city'),
    billing_country: translate('settings.labels.billing_country'),
    billing_type: translate('settings.labels.billing_type'),
    account_holder: translate('settings.labels.account_holder'),
    iban_bban: translate('settings.labels.iban_bban'),
    bic_swift: translate('settings.labels.bic_swift'),
})

export const LEGAL_STRUCTURE_TYPES = {
    privatePerson: 'private_person',
    selfEmployedBelowTax: 'self_employed_under_tva',
    selfEmployedOverTax: 'self_employed_beyond_tva',
    firmSubjectToVAT: 'firm_with_tva',
    firmSubjectToVATWizdeo: 'firm_with_tva_wizdeo_invoice',
    firmExemptFromVAT: 'firm_without_tva',
    firmExemptFromVATWizdeo: 'firm_without_tva_wizdeo_invoice',
}

export const BILLING_TYPES = {
    none: 'none',
    callForInvoicing: 'call_for_invoicing',
    autoInvoice: 'auto_invoice',
    callForInvoicingSelfEmployed: 'call_for_invoicing_self_employed',
    invoice: 'invoice',
    externalInvoice: 'external_invoice',
}

export const FORM_KEYS = {
    talent: {
        firstname: 'firstname',
        lastname: 'lastname',
        email: 'email',
        email_notifications: 'email_notifications',
    },
    zoho_account: {
        legalStructure: 'legal_structure',
        name: 'name',
        siret: 'siret',
        tvaIntra: 'tva_intra',
        billingName: 'billing_name',
        billingEmail: 'billing_email',
        language: 'language',
        billingCompany: 'billing_company',
        billingStreet: 'billing_street',
        billingCode: 'billing_code',
        billingCity: 'billing_city',
        billingCountry: 'billing_country',
        billingType: 'billing_type',
        accountHolder: 'account_holder',
        ibanBban: 'iban_bban',
        bicSwift: 'bic_swift',
    },
}

const selectItems = {
    legal_structure: Object.keys(LEGAL_STRUCTURE_TYPES).map((key) => ({
        label: labels()[LEGAL_STRUCTURE_TYPES[key]],
        value: LEGAL_STRUCTURE_TYPES[key],
    })),
}

export const validators = () => {
    const message = (type = 'default') => {
        if (type === 'required') return translate('settings.field_required')
        return translate('settings.field_invalid')
    }

    return {
        talent: Yup.object().shape({
            firstname: Yup.string().required(message('required')),
            email: Yup.string().email(message()).required(message('required')),
        }),
        zoho_account: Yup.object().shape({
            billing_email: Yup.string().email(message()),
            iban_bban: Yup.string().min(14, message()).max(34, message()),
            bic_swift: Yup.string().min(8, message()).max(11, message()),
        }),
    }
}

export const isPrivatePerson = (value) =>
    value === LEGAL_STRUCTURE_TYPES.privatePerson

export const getLegalStructure = (account) => {
    const legalStatus = account.legal_status_draft ?? account.legal_status
    const billingType = account.billing_type_draft ?? account.billing_type
    const applicableVat = account.applicable_vat_draft ?? account.applicable_vat

    switch (legalStatus) {
        case 'Self Employed':
            if (billingType === BILLING_TYPES.callForInvoicingSelfEmployed) {
                return LEGAL_STRUCTURE_TYPES.selfEmployedOverTax
            }
            return LEGAL_STRUCTURE_TYPES.selfEmployedBelowTax
        case 'Firm':
            if (applicableVat) {
                if (billingType === BILLING_TYPES.externalInvoice) {
                    return LEGAL_STRUCTURE_TYPES.firmSubjectToVATWizdeo
                }
                return LEGAL_STRUCTURE_TYPES.firmSubjectToVAT
            }
            if (billingType === BILLING_TYPES.externalInvoice) {
                return LEGAL_STRUCTURE_TYPES.firmExemptFromVATWizdeo
            }
            return LEGAL_STRUCTURE_TYPES.firmExemptFromVAT
        default:
            return LEGAL_STRUCTURE_TYPES.privatePerson
    }
}

export const getError = (props, key, data) => {
    // Si on a une valeur dans le draft, le champ est en cours de vérification
    const errors = {
        isInvalid: (error) => ({
            text: error,
            type: 'error',
        }),
        isChecking: {
            text: translate('settings.checking'),
            type: 'warning',
        },
    }

    if (props.errors[key]) return errors.isInvalid(props.errors[key])

    if (key === FORM_KEYS.zoho_account.legalStructure) {
        if (data['legal_status_draft']) return errors.isChecking
    }

    if (data[key + '_draft']) return errors.isChecking

    return null
}

export const getInitialValues = (data, type = 'talent') => {
    let initialValues = {}

    Object.keys(FORM_KEYS[type]).forEach((key) => {
        const index = FORM_KEYS[type][key]
        if (type === 'talent') {
            initialValues[key] = data[key]
        } else if (type === 'zoho_account') {
            if (index === 'legal_structure') {
                initialValues[index] = getLegalStructure(data)
            } else {
                // Check si on a une valeur dans le "draft" pour chaque autre champ de formulaire
                initialValues[index] = data[index + '_draft'] ?? data[index]
            }
        }
    })

    return initialValues
}

export const Input = ({
    props,
    field,
    disabled = false,
    account = null,
    talent = null,
    type = 'text',
    extraStyle = null,
}) => {
    const error = getError(props, field, talent ?? account)

    let content = (
        <input
            disabled={disabled}
            placeholder={labels()[field]}
            type={type}
            value={props.values[field]}
            id={field}
            onChange={props.handleChange}
        />
    )

    if (type === 'select') {
        const options = selectItems[field]

        content = (
            <CustomSelect
                full
                defaultValue={options[0]}
                options={options}
                onChange={(item) => props.setFieldValue(field, item.value)}
                value={props.values[field]}
            />
        )
    }

    if (type === 'checkbox') {
        return (
            <div className='input-field input-checkbox '>
                <input
                    checked={props.values[field]}
                    type='checkbox'
                    onChange={props.handleChange}
                    name={field}
                    value={props.values[field]}
                    className='filled-in'
                    id={field}
                />
                <label for={field}>
                    {labels()[field]}
                </label>
            </div>
        )
    }
    return (
        <div
            className={`input-field input-${type} ${error ? error.type : ''}`}
            style={extraStyle && extraStyle.container}
            data-message={error ? error.text : null}
        >
            <label htmlFor={field}>{labels()[field]}</label>
            {content}
        </div>
    )
}
