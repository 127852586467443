import React, { useLayoutEffect } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { useCookie } from 'react-use'
import AppNavigator from './components/navigation/AppNavigator.js'
import { onMessageListener } from './firebase'
import './styles/App.scss'

import moment from 'moment'
import 'moment/locale/fr'
import AuthProvider from './components/auth/AuthProvider.js'
import { createTheme, StylesProvider, ThemeProvider } from '@material-ui/core'
import AnalyticsProvider from './AnalyticsProvider.js'
import { GoogleOAuthProvider } from '@react-oauth/google'

const theme = createTheme({
	palette: {
		primary: {
			main: '#1976D2',
		},
	},
	breakpoints: {
		values: {
			xs: 480,
			sm: 576,
			md: 768,
			lg: 992,
			xl: 1200,
		},
	},
})

const App = () => {
	const { i18n } = useTranslation()
	const [languageCookie, updateLanguageCookie] = useCookie('lang')

	const init = () => {
		if (!languageCookie) {
			updateLanguageCookie('fr')
		}
		const language = languageCookie ?? 'fr'
		i18n.changeLanguage(language)
		moment.locale(language)
	}

	useLayoutEffect(init, [])

	onMessageListener()

	return (
		<AnalyticsProvider>
			<ThemeProvider theme={theme}>
				<StylesProvider injectFirst>
					<I18nextProvider i18n={i18n}>
						<GoogleOAuthProvider
							clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
						>
							<AuthProvider>
								<AppNavigator />
							</AuthProvider>
						</GoogleOAuthProvider>
					</I18nextProvider>
				</StylesProvider>
			</ThemeProvider>
		</AnalyticsProvider>
	)
}

export default App
