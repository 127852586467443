import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import Header from '../../components/layout/Header'
import Loader from '../../components/UI/Loader'
import { getCopyrights } from '../../store/reducers/mcnReducer'
import Paginator from '../../components/UI/Paginator'
import { Container } from '../../components/Layout'
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core'
import { FaInfoCircle } from 'react-icons/fa'
import styled from 'styled-components'
import Copyright from '../../model/Copyright'
import CopyrightRow from '../../components/mcn/CopyrightRow'
import CustomTable2 from '../../components/UI/CustomTable2'

const CopyrightsPage = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const { channels } = useSelector((state) => state.talent.data)
    const {
        data: copyrights,
        loading,
        pagination,
    } = useSelector((state) => state.mcn.copyrights)

    const [page, setPage] = useState(1)

    let content

    const load = (page = 1, forced = false) => {
        if (!copyrights || !copyrights[page] || forced) {
            const channelIds = channels.map((channel) => channel.id)
            const payload = {
                channelIds,
                page,
            }
            dispatch(getCopyrights(payload))
        }
    }

    useLayoutEffect(load, [])

    useUpdateEffect(() => {
        console.log(`page`, page)
        load(page)
    }, [page])

    const handlePageChange = (event, value) => setPage(value)

    const paginator =
        copyrights && pagination.pageCount > 1 ? (
            <PaginatorWrapper>
                <Paginator
                    page={page}
                    count={pagination ? pagination.pageCount : 0}
                    onPageChange={handlePageChange}
                />
            </PaginatorWrapper>
        ) : null

    if (!copyrights[page] || loading) {
        content = <Loader />
    } else {
        let test = (
            <CustomTable2
                page={page}
                columns={Copyright.COLUMNS}
                rows={copyrights[page].map((copyright) => (
                    <CopyrightRow data={copyright} />
                ))}
            />
        )
        content = (
            <TableContainer className='mini-scroll' component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {Copyright.COLUMNS.map((col, index) => (
                                <TableCell align={index > 0 ? 'center' : 'left'}>
                                    {col}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {copyrights[page].map((copyright) => (
                            <CopyrightRow data={copyright} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
        content = test
    }

    return (
        <>
            <Header
                title={
                    <Wrapper>
                        {t('pages.copyrights')}
                        <Tooltip arrow title={t('copyrights.tooltip')}>
                            <IconButton aria-label='delete'>
                                <FaInfoCircle color='white' />
                            </IconButton>
                        </Tooltip>
                    </Wrapper>
                }
            />
            <Container>
                {paginator}
                {content}
                {paginator}
            </Container>
        </>
    )
}

export default CopyrightsPage

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const PaginatorWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`
