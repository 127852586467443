import React from 'react'
import { useSelector } from 'react-redux'

const AnalyticsProvider = ({ children }) => {
    const { data: talent } = useSelector((state) => state.talent)

    window.dataLayer = window.dataLayer || []
    
    function gtag() {
        window.dataLayer.push(arguments)
    }

    React.useEffect(() => {
        gtag('js', new Date())
        if (talent?.id) {
            gtag('config', process.env.REACT_APP_GA_TRACKER_ID, {
                user_id: talent.id,
                custom_map: { dimension1: 'user' },
            })
            gtag('event', 'user_dimension', { user: talent.id })
        } else {
            gtag('config', 'G-F8C81XEGPN' );
        }
    })

    return <>{children}</>
}

export default AnalyticsProvider